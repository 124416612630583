import React, { useState } from "react"
import PropTypes from "prop-types"
import { Formik, Form, Field } from "formik"
import TextField from "@material-ui/core/TextField"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import { FormHeader } from "./form-header"
import { Button, Grid } from "@material-ui/core"
import NumberFormat from "react-number-format"
import "../styles/styles.css"
import * as yup from "yup"

const useStyles = makeStyles(theme => ({
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  buttonLg: {
    margin: "13px 0 0 0",
    fontSize: "21px",
    padding:"11px 38px",
  },
  socialGrid: {
    maxWidth: "400px",
  },
  socialLink: {
    position:"relative",
    top:0,
    transition:"0.2s ease-in-out",
    '&:hover': {
      top:"-6px",
    },
  },
  mb60: {
    marginBottom: "60px",
  },
  shareHeading: {
    marginBottom:0,
    fontWeight:600,
    ["@media (max-width:599px)"]: {
      marginTop:"-85px",
    }
  },
  paraLarge: {
    fontSize:"20px",
    lineHeight:"1.5",
  },
  shareInner: {
    marginBottom:"20px",
  },
  fundraiserLink: {
    color:"#ea5045",
  },
  shareHeader: {
    width:"60%",
    margin:"0 0 28px 0",
    ["@media (max-width:1000px)"]: {
      paddingLeft:"1rem",
      paddingRight:"1rem",
      width:"100%",
    }
  },
  shareField: {
    '& .MuiFormControl-marginNormal': {
      margin:"0 0 5px 0",
      width:"100%",
    },
    '& .MuiFormLabel-root': {
      color: "#dd8e86"
    },
  },
  shareSection: {
    maxWidth:"400px",
  },
  fundraiserWrap: {
    ["@media (max-width:1000px)"]: {
      paddingLeft:"1rem",
      paddingRight:"1rem",
    }
  },  
}))

const validationSchema = yup.object({
  email: yup.string().email("Invalid email").required("Email is required"),
})

export const FormFundraiseShare = ({
  formData,
  setFormData,
  nextStep,
  prevStep,
}) => {
  const classes = useStyles()
  const [direction, setDirection] = useState("back")
  const targetPercentage = formData.totalRaised / formData.totalTarget * 100;
  const strokePercentage = targetPercentage + ',100';
  return (
    <>
      <div className="no-progress-bar extra-container">
        <div className="container relative">
          <Formik
            initialValues={{
              toggle: false,
              checked: [],
              email: "",
            }}
            onSubmit={values => {
              setFormData(Object.assign(formData, values))
              direction === "back" ? prevStep() : nextStep()
            }}
            validationSchema={validationSchema}
          >
            {({ errors, touched, values }) => (
              <Form className={classes.form}>
                <h2 className={classes.shareHeader}>You did it, {formData.firstName}! Thank you so much for supporting {formData.organisation.name}.</h2>
                <Grid
                  container
                  direction="row"
                  className={classes.fundraiserWrap}
                >
                  <Grid item sm={5}>
                    <h3 className="form-subheading form-subheading--sm">
                      Your fundraiser is now LIVE at{" "}
                      <a href="{formData.fundraiserPermalink}" className={classes.fundraiserLink}>
                        {formData.fundraiserPermalink}
                      </a>
                    </h3>
                    <h3 className="form-subheading">Now let's get the word out!</h3>
                  </Grid>
                  <Grid item sm={5}>
                    <div className="pie-chart__wrapper pie-chart--sm">
                      <div className="pie-chart__inner">
                        
                          
                        <svg className="circle-chart" viewBox="0 0 33.83098862 33.83098862" width="280" height="280" xmlns="http://www.w3.org/2000/svg">
                          <circle className="circle-chart__background" stroke="#2b5b88" stroke-width="1" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431"></circle>
                          <circle className="circle-chart__circle" stroke="#50bcbd" stroke-width="1" stroke-dasharray={ strokePercentage } stroke-linecap="round" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431"></circle>
                          <g className="circle-chart__info">
                          </g>
                        </svg>
                        <div className="circle-chart__text">
                          <div className="circle-chart__percent">
                            <NumberFormat value={formData.totalRaised} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                          </div>
                          <div className="circle-chart__subline">currently achieved of <NumberFormat value={formData.totalTarget} displayType={'text'} thousandSeparator={true} prefix={'£'} /> target</div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                
                <div className="bg--grey bg--image extra-container">
                  <div className="container">
                    <Grid
                      container
                      direction="row"
                      alignItems="baseline"
                      justify="space-between"
                      className={classes.mb60}
                    >
                      <Grid item sm={5}>
                        <h3 className="form-subheading">
                          Lead by example and donate to your own fundraiser!
                        </h3>
                        <p className={classes.paraLarge}>Did you know, that people who donate to their own fundraisers raise roughly 125% more from others?</p>
                        <Button variant="contained" color="secondary" className={classes.buttonLg}>
                          Donate
                        </Button>
                      </Grid>
                      <Grid item sm={5}>
                        <h3 className="form-subheading">Share it through Social...</h3>
                        <div className={classes.mb60}>
                          <Grid
                            container
                            alignItems="flex-start"
                            justify="flex-start"
                            className={classes.socialGrid}
                          >
                            <a href="#" className={classes.socialLink}>
                              <svg enableBackground="new 0 0 112.196 112.196" viewBox="0 0 112.196 112.196" xmlns="http://www.w3.org/2000/svg" className="share-icon">
                                <circle cx="56.098" cy="56.098" fill={formData.colour} r="56.098"/>
                                <path d="m70.201 58.294h-10.01v36.672h-15.166v-36.672h-7.213v-12.888h7.213v-8.34c0-5.964 2.833-15.303 15.301-15.303l11.234.047v12.51h-8.151c-1.337 0-3.217.668-3.217 3.513v7.585h11.334z" fill="#fff"/>
                              </svg>
                            </a>
                            <a href="#" className={classes.socialLink}>
                              <svg enableBackground="new 0 0 112.197 112.197" viewBox="0 0 112.197 112.197" xmlns="http://www.w3.org/2000/svg" className="share-icon">
                                <circle cx="56.099" cy="56.098" fill={formData.colour} r="56.098"/>
                                <path d="m90.461 40.316c-2.404 1.066-4.99 1.787-7.702 2.109 2.769-1.659 4.894-4.284 5.897-7.417-2.591 1.537-5.462 2.652-8.515 3.253-2.446-2.605-5.931-4.233-9.79-4.233-7.404 0-13.409 6.005-13.409 13.409 0 1.051.119 2.074.349 3.056-11.144-.559-21.025-5.897-27.639-14.012-1.154 1.98-1.816 4.285-1.816 6.742 0 4.651 2.369 8.757 5.965 11.161-2.197-.069-4.266-.672-6.073-1.679-.001.057-.001.114-.001.17 0 6.497 4.624 11.916 10.757 13.147-1.124.308-2.311.471-3.532.471-.866 0-1.705-.083-2.523-.239 1.706 5.326 6.657 9.203 12.526 9.312-4.59 3.597-10.371 5.74-16.655 5.74-1.08 0-2.15-.063-3.197-.188 5.931 3.806 12.981 6.025 20.553 6.025 24.664 0 38.152-20.432 38.152-38.153 0-.581-.013-1.16-.039-1.734 2.622-1.89 4.895-4.251 6.692-6.94z" fill="#f1f2f2"/>
                              </svg>
                            </a>
                            <a href="#" className={classes.socialLink}>
                              <svg enableBackground="new 0 0 291.319 291.319" viewBox="0 0 291.319 291.319" xmlns="http://www.w3.org/2000/svg" className="share-icon">
                                <path d="m145.659 0c80.44 0 145.66 65.219 145.66 145.66s-65.219 145.659-145.659 145.659-145.66-65.219-145.66-145.659 65.21-145.66 145.659-145.66z" fill={formData.colour} />
                                <path d="m195.93 63.708h-100.55c-17.47 0-31.672 14.211-31.672 31.672v100.56c0 17.47 14.211 31.672 31.672 31.672h100.56c17.47 0 31.672-14.211 31.672-31.672v-100.56c-.001-17.461-14.212-31.672-31.682-31.672zm9.978 18.326 3.587-.009v27.202l-27.402.091-.091-27.202s23.906-.082 23.906-.082zm-60.248 36.205c22.732 0 27.42 21.339 27.42 27.429 0 15.103-12.308 27.411-27.42 27.411-15.121 0-27.42-12.308-27.42-27.411-.01-6.09 4.688-27.429 27.42-27.429zm63.99 75.716c0 8.658-7.037 15.704-15.713 15.704h-96.864c-8.667 0-15.713-7.037-15.713-15.704v-66.539h22.759c-2.112 5.198-3.305 12.299-3.305 18.253 0 24.708 20.101 44.818 44.818 44.818s44.808-20.11 44.808-44.818c0-5.954-1.193-13.055-3.296-18.253h22.486v66.539z" fill="#fff"/>
                              </svg>
                            </a>
                          </Grid>
                        </div>
                      </Grid>
                      
                    </Grid>
                    
                    <h3 className={classes.shareHeading}>Share it by email...</h3>
                    <div className={classes.shareSection}>
                      <div className={classes.shareInner}>
                        <div className={classes.shareField}>
                          <Field
                            name="emailshare1"
                            label="email1@domain.com"
                            margin="normal"
                            as={TextField}
                          />
                        </div>
                        <div className={classes.shareField}>
                          <Field
                            name="emailshare2"
                            label="email2@domain.com"
                            margin="normal"
                            as={TextField}
                          />
                        </div>
                        <div className={classes.shareField}>
                          <Field
                            name="emailshare3"
                            label="email3@domain.com"
                            margin="normal"
                            as={TextField}
                          />
                        </div>
                      </div>
                      <Button variant="contained" color="secondary" className={classes.buttonLg}>
                        Share
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}

FormFundraiseShare.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
}
