import React, { useState, useEffect } from "react"
import { FormUserDetails } from "./FormUserDetails"
import { FormFundraise } from "./FormFundraise"
import { FormFundraiseDetails } from "./FormFundraiseDetails"
import { FormFundraisePhoto } from "./FormFundraisePhoto"
import { FormFundraiseTerms } from "./FormFundraiseTerms"
import { FormFundraiseShare } from "./FormFundraiseShare"
import { FormGridSearch } from "./FormGridSearch"
import { Confirm } from "./Confirm"
import { Success } from "./Success"
import "../styles/styles.css"
import { ProgressBar } from "./ProgressBar"
import { FormHeader } from "./form-header"

export const DonationForm = () => {
  // State variables
  const [step, setStep] = useState(1)
  const [stepClasses, setStepClasses] = useState([])
  const [transitioning, setTransitioning] = useState(false)
  const [transitionDirection, setTransitionDirection] = useState("forwards")
  const [isComplete, setIsComplete] = useState(false)

  // const transitionClasses = {
  //   show: "form-show",
  //   hide: "form-hidden",
  //   showing: "form-showing",
  // }

  // Form variables
  const organisation = {
    name: "Test Organisation International",
    informalName: "Test Organisation",
    namePlural: "Test Organisation's",
  }

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    targetAmount: 0,
    waysToFundraise: [],
    fundraiserDescription: "",
    fundraiserPhoto: {},
    emailAddress: "",
    termsAgreed: false,
    shareEmailAddresses: [],
    organisation: organisation,
    totalRaised: "128000",
    totalTarget: "200000",
    colour: "#ea5045",
    fundraiserPermalink: "tinyurl.com/welldone",
  })

  const submitForm = () => {
    // Submit form data using axios or something else
    // Form data is within variable formData
    setIsComplete(true)
    nextStep()
  }

  // For progress bar and pagination
  const numberOfSteps = 7
  const progress = (100 / numberOfSteps) * step
  const nextStep = () => {
    setTransition()
    setTransitionDirection("forwards")
    setStep(prev => prev + 1)
  }
  const prevStep = () => {
    setTransition()
    setTransitionDirection("backwards")
    setStep(prev => prev - 1)
  }

  const transitionTimeout = 5

  const setTransition = () => {
    window.scrollTo(0, 0)
    setTransitioning(true)
    setTimeout(() => setTransitioning(false), transitionTimeout)
  }

  // Set transition classes
  const getStepClasses = formStep => {
    if (transitioning && formStep == step) {
      return "form-entering"
    } else if (!transitioning && formStep === step) {
      return "form-entered"
    } else {
      if (
        !transitioning &&
        transitionDirection == "forwards" &&
        formStep == step - 1
      ) {
        return "form-exit"
      } else if (
        !transitioning &&
        transitionDirection == "backwards" &&
        formStep == step + 1
      ) {
        return "form-exit"
      } else if (
        transitioning &&
        transitionDirection == "forwards" &&
        formStep == step - 1
      ) {
        return "form-exiting"
      } else if (
        transitioning &&
        transitionDirection == "backwards" &&
        formStep == step + 1
      ) {
        return "form-exiting"
      } else {
        return "form-hidden"
      }
    }
  }

  console.log(formData)

  // Form header variables
  const formIntroText = [
    ``,
    `Thank you for choosing to fundraise with ${formData.organisation.name}`,
    `Thank you for choosing to fundraise with ${formData.organisation.name}`,
    `It's thanks to people like you that we can keep making a difference through life-saving research.`,
    `It's thanks to people like you that we can keep making a difference through life-saving research.`,
    `It is thanks to people like you that we can keep making a difference through life-saving research.`,
    ``,
  ]

  return (
    <>
      <FormHeader title={formIntroText[step - 1]} />

      {![1, 7].includes(step) && <ProgressBar progress={progress} />}
      <div className={getStepClasses(1)}>
        <FormGridSearch
          formData={formData}
          setFormData={setFormData}
          nextStep={nextStep}
          prevStep={prevStep}
        />
      </div>
      <div className={getStepClasses(2)}>
        <FormUserDetails
          formData={formData}
          setFormData={setFormData}
          nextStep={nextStep}
          prevStep={prevStep}
        />
      </div>
      <div className={getStepClasses(3)}>
        <FormFundraise
          formData={formData}
          setFormData={setFormData}
          nextStep={nextStep}
          prevStep={prevStep}
        />
      </div>
      <div className={getStepClasses(4)}>
        <FormFundraiseDetails
          formData={formData}
          setFormData={setFormData}
          nextStep={nextStep}
          prevStep={prevStep}
        />
      </div>
      <div className={getStepClasses(5)}>
        <FormFundraisePhoto
          formData={formData}
          setFormData={setFormData}
          nextStep={nextStep}
          prevStep={prevStep}
        />
      </div>
      <div className={getStepClasses(6)}>
        <FormFundraiseTerms
          formData={formData}
          setFormData={setFormData}
          nextStep={nextStep}
          prevStep={prevStep}
          submitForm={submitForm}
        />
      </div>
      <div className={getStepClasses(7)}>
        <FormFundraiseShare
          formData={formData}
          setFormData={setFormData}
          nextStep={nextStep}
          prevStep={prevStep}
        />
      </div>
    </>
  )

  // switch (step) {
  //   case 1:
  //     return (
  //       <>
  //         <FormGridSearch
  //           formData={formData}
  //           setFormData={setFormData}
  //           nextStep={nextStep}
  //         />
  //       </>
  //     )
  //   case 2:
  //     return (
  //       <>
  //         <ProgressBar progress={progress} />
  //         <FormUserDetails
  //           formData={formData}
  //           setFormData={setFormData}
  //           nextStep={nextStep}
  //         />
  //       </>
  //     )
  //   case 3:
  //     return (
  //       <>
  //         <ProgressBar progress={progress} />
  //         <FormFundraise
  //           formData={formData}
  //           setFormData={setFormData}
  //           nextStep={nextStep}
  //           prevStep={prevStep}
  //         />
  //       </>
  //     )
  //   case 4:
  //     return (
  //       <>
  //         <ProgressBar progress={progress} />
  //         <FormFundraiseDetails
  //           formData={formData}
  //           setFormData={setFormData}
  //           nextStep={nextStep}
  //           prevStep={prevStep}
  //         />
  //       </>
  //     )
  //   case 5:
  //     return (
  //       <>
  //         <ProgressBar progress={progress} />
  //         <FormFundraisePhoto
  //           formData={formData}
  //           setFormData={setFormData}
  //           nextStep={nextStep}
  //           prevStep={prevStep}
  //         />
  //       </>
  //     )
  //   case 6:
  //     return (
  //       <>
  //         <ProgressBar progress={progress} />
  //         <FormFundraiseTerms
  //           formData={formData}
  //           setFormData={setFormData}
  //           nextStep={nextStep}
  //           prevStep={prevStep}
  //         />
  //       </>
  //     )
  //   case 7:
  //     return (
  //       <>
  //         <ProgressBar progress={progress} />
  //         <FormFundraiseShare
  //           formData={formData}
  //           setFormData={setFormData}
  //           nextStep={nextStep}
  //           prevStep={prevStep}
  //         />
  //       </>
  //     )
  //   case 8:
  //     return (
  //       <Confirm formData={formData} nextStep={nextStep} prevStep={prevStep} />
  //     )
  //   default:
  //     return <Success />
  // }
}
