import React, { useState } from "react"
import PropTypes from "prop-types"
import { Formik, Form, Field } from "formik"
import { makeStyles } from "@material-ui/core/styles"
import { FormHeader } from "./form-header"
import { Button } from "@material-ui/core"
import { Grid } from "@material-ui/core"
import "../styles/styles.css"

const useStyles = makeStyles(theme => ({
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  button: {
    margin: theme.spacing(1),
  },
  radioImg: {
    marginBottom:"14px",
    display:"block",
    height:190,
    width:"100%",
    backgroundRepeat:"no-repeat",
    backgroundSize:"cover",
    backgroundPosition:"center center",
  },
  radioWithImg: {
    position:"relative",
    '& input': {
      opacity:0,
      position:"absolute",
    },
    '& input:checked + label:after': {
      backgroundColor:"rgba(234, 80, 69, 0.7)",
    },
    '& input:checked + label:before': {
      opacity:1,
      transform: "scale(1)",
      transition:"0.15s ease-in-out",
    },
    '& label': {
      fontWeight:600,
      fontSize:"16px",
      lineHeight:"1.3",
      cursor:"pointer",
      display:"block",
      '&:hover': {
        '&:after': {
          backgroundColor:"rgba(255,255,255,0.2)",
        },
      },
      '&:before': {
        content:"''",
        display:"inline-block",
        opacity:0,
        height:120,
        width:120,
        background:"url(/images/checked.svg) center center / contain no-repeat",
        position:"absolute",
        top:"38px",
        left:0,
        right:0,
        margin:"0 auto",
        zIndex:1,
        transition:"0.05s ease-in-out",
        transform: "scale(0.9)",
      },
      '&:after': {
        content:"''",
        display:"block",
        backgroundColor:"rgba(0,0,0,0)",
        width:"100%",
        height:190,
        position:"absolute",
        top:0,
        left:0,
        transition:"0.15s ease-in-out",
      },
    }
  },
}))

const RadioButton = ({
  field: { name, value, onChange, onBlur },
  id,
  label,
  className,
  ...props
}) => {
  return (
    <div>
      <input
        name={name}
        id={id}
        type="radio"
        value={id} // could be something else for output?
        checked={id === value}
        onChange={onChange}
        onBlur={onBlur}
        {...props}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  )
}

export const FormFundraise = ({
  formData,
  setFormData,
  nextStep,
  prevStep,
}) => {
  const classes = useStyles()
  const [direction, setDirection] = useState("back")
  return (
    <>
      {/* <FormHeader
        title={`Thank you for choosing to fundraise with ${formData.organisation.name}`}
      /> */}
      <Formik
        initialValues={formData}
        onSubmit={values => {
          setFormData(Object.assign(formData, values))
          direction === "back" ? prevStep() : nextStep()
        }}
      >
        <Form className={classes.form}>
          <div className="bg--grey bg--image extra-container">
            <div className="container">
              <h3 className="form-subheading form-subheading--short">
                Wow, thanks for being awesome, {formData.firstName}! How do you plan
                to raise £{formData.targetAmount}?
              </h3>
              <p className="form-label">I'm going to... </p>
              <Grid container direction="row" alignItems="baseline" spacing={4}>
                <Grid item sm={3} xs={6}>
                  <div className={classes.radioWithImg}>
                    <div className={classes.radioImg} style={{backgroundImage:'url(https://placeimg.com/300/270/nature)'}}></div>
                    <Field
                      component={RadioButton}
                      name="waysToFundraise"
                      id="Organise a gathering / bring people together"
                      label="Organise a gathering / bring people together"
                    />
                  </div>
                  
                </Grid>
                <Grid item sm={3} xs={6}>
                  <div className={classes.radioWithImg}>
                    <div className={classes.radioImg} style={{backgroundImage:'url(https://placeimg.com/300/270/nature)'}}></div>
                    <Field
                      component={RadioButton}
                      className={classes.radioWithImg}
                      name="waysToFundraise"
                      id="waysToFundraise2"
                      label="Raise sponsorship for a personal challenge"
                    />
                  </div>
                </Grid>
                <Grid item sm={3} xs={6}>
                  <div className={classes.radioWithImg}>
                    <div className={classes.radioImg} style={{backgroundImage:'url(https://placeimg.com/300/270/nature)'}}></div>
                    <Field
                      component={RadioButton}
                      className={classes.radioWithImg}
                      name="waysToFundraise"
                      id="waysToFundraise3"
                      label="Remember someone special"
                    />
                  </div>
                </Grid>
                <Grid item sm={3} xs={6}>
                  <div className={classes.radioWithImg}>
                    <div className={classes.radioImg} style={{backgroundImage:'url(https://placeimg.com/300/270/nature)'}}></div>
                    <Field
                      component={RadioButton}
                      className={classes.radioWithImg}
                      name="waysToFundraise"
                      id="waysToFundraise4"
                      label="Celebrate an occasion"
                    />
                  </div>
                </Grid>
                <Grid item sm={3} xs={6}>
                  <div className={classes.radioWithImg}>
                    <div className={classes.radioImg} style={{backgroundImage:'url(https://placeimg.com/300/270/nature)'}}></div>
                    <Field
                      component={RadioButton}
                      className={classes.radioWithImg}
                      name="waysToFundraise"
                      id="waysToFundraise5"
                      label="Etc..."
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                alignItems="baseline"
                justify="space-between"
                className="pagination-buttons"
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => setDirection("back")}
                >
                  Back
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  onClick={() => setDirection("forward")}
                >
                  Next
                </Button>
            </Grid>
          </div>
          </div>
        </Form>
      </Formik>
    </>
  )
}

FormFundraise.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
}
