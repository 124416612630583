import React from "react"
import { withStyles } from "@material-ui/core/styles"
import LinearProgress from "@material-ui/core/LinearProgress"
import { Grid } from "@material-ui/core"

const BorderLinearProgress = withStyles(theme => ({
  root: {
    height: 32,
    width: "100%",
    borderRadius: 30,
    flex: 1,
  },
  colorPrimary: {
    backgroundColor:"#f0f8f9",
  },
  bar: {
    borderRadius: 30,
    backgroundColor: "#50bcbd",
  },
}))(LinearProgress)

export const ProgressBar = ({ progress }) => {
  return (
    <div>
      <div className="extra-container header-section header-section--bottom">
        <div className="container">
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <h4 className="progress__header">Progress</h4>
            <BorderLinearProgress variant="determinate" value={progress} />
          </Grid>
        </div>
      </div>
    </div>
  )
}
