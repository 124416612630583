import React, { useState } from "react"
import PropTypes from "prop-types"
import { Formik, Form, Field } from "formik"
import { makeStyles } from "@material-ui/core/styles"
import { FormHeader } from "./form-header"
import { Button, Grid } from "@material-ui/core"
import { DropzoneArea } from "material-ui-dropzone"
import "../styles/styles.css"

const useStyles = makeStyles(theme => ({
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    margin: theme.spacing(1),
  },
}))

export const FormFundraisePhoto = ({
  formData,
  setFormData,
  nextStep,
  prevStep,
}) => {
  const classes = useStyles()
  const [direction, setDirection] = useState("back")
  return (
    <>
      {/* <FormHeader
        title={`${formData.firstName}, it is thanks to people like you that we can keep making a difference through life-saving research.`}
      /> */}
      <Formik
        initialValues={formData}
        onSubmit={values => {
          setFormData(Object.assign(formData, values))
          direction === "back" ? prevStep() : nextStep()
        }}
      >
        <Form className={classes.form}>
          <div className="extra-container details-wrapper">
            <div className="bg--grey bg--image">
              <div className="container">
                <h3 className="form-subheading">
                  Sounds great! We've used our generic icon for your fundraiser, which
                  you should definitely replace!
                </h3>
                <Grid
                  container
                  direction="row"
                  alignItems="flex-start"
                  justify="space-between"
                  wrap="nowrap"
                  spacing={4}
                >
                  <Grid item xs={12} sm={4}>
                    <DropzoneArea
                      acceptedFiles={["image/*"]}
                      dropzoneText={"Drag and drop a photo, or click here"}
                      onChange={files => console.log("Files:", files)}
                      filesLimit={1}
                      dropzoneClass={"form-dropzone"}
                      showAlerts={false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <h4 className="help-text">
                        Did you know, that people who add a custom photo raise on
                        average 10 times more than those that don't?
                    </h4>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  alignItems="baseline"
                  justify="space-between"
                  className="pagination-buttons"
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => setDirection("back")}
                  >
                    Back
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    onClick={() => setDirection("forward")}
                  >
                    Next
                  </Button>
                </Grid>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    </>
  )
}

FormFundraisePhoto.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
}
