import React from "react"

import Layout from "../components/layout"
import { DonationForm } from "../components/donation-form"
import "../styles/styles.css";

const IndexPage = () => (
  <Layout>
    <DonationForm />
  </Layout>
)

export default IndexPage
