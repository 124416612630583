import React, { useState } from "react"
import PropTypes from "prop-types"
import { Formik, Form, Field } from "formik"
import TextField from "@material-ui/core/TextField"
import { FormHeader } from "./form-header"
import { makeStyles } from "@material-ui/core/styles"
import { Button, Grid } from "@material-ui/core"
import * as yup from "yup"
import AOS from "aos"
import classNames from "classnames"
import { Watch } from "scrollmonitor-react"
import "aos/dist/aos.css"
import "../styles/styles.css"

const useStyles = makeStyles(theme => ({
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  input: {
    color:"#24195c",
  },
  button: {
    margin: theme.spacing(1),
  },
  formField: {
    borderColor:"#dd8e86",
    color:"#24195c",
  },
}))

const validationSchema = yup.object({
  firstName: yup.string().required("First Name is required").max(20),
  lastName: yup.string().required("Last Name is required").max(20),
  targetAmount: yup.number().required("Amount is required"),
})

// do this https://medium.com/@PostgradExpat/animating-elements-react-components-in-gatsby-js-with-aos-scrollmonitor-react-and-classnames-c1531b5a7886

export const FormUserDetails = ({
  formData,
  setFormData,
  nextStep,
  prevStep,
}) => {
  const [direction, setDirection] = useState("back")
  const classes = useStyles()
  return (
    <div className="bg--grey extra-container bg--image">
      <div className="container">
        {/* <FormHeader
          title={`Thank you for choosing to fundraise with ${formData.organisation.name}`}
        /> */}
        <h3 className="form-subheading">
          Could you tell us a little about yourself?
        </h3>
        <Formik
          initialValues={formData}
          onSubmit={values => {
            console.log("submit")
            setFormData(Object.assign(formData, values))
            direction === "back" ? prevStep() : nextStep()
          }}
          validationSchema={validationSchema}
        >
          {({ errors, touched }) => (
            <Form className={classes.form}>
              <Grid container direction="row" alignItems="baseline">
                <p className="form-label form-label--inline">My name is </p>
                <Field
                  name="firstName"
                  label="First Name"
                  margin="normal"
                  className="formField"
                  as={TextField}
                  error={touched.firstName && errors.firstName}
                  helperText={touched.firstName && errors.firstName}
                />
                <Field
                  name="lastName"
                  label="Last Name"
                  margin="normal"
                  className="formField"
                  as={TextField}
                  error={touched.lastName && errors.lastName}
                  helperText={touched.lastName && errors.lastName}
                />
              </Grid>
              <Grid container direction="row" alignItems="baseline">
                <p className="form-label form-label--inline">
                  and I want to raise{" "}
                </p>
                <Field
                  name="targetAmount"
                  label="£ Amount"
                  margin="normal"
                  className="formField"
                  as={TextField}
                  error={touched.targetAmount && errors.targetAmount}
                  helperText="Please enter a number"
                />
              </Grid>
              <Grid container direction="row" alignItems="baseline">
                {formData.organisation && (
                  <p className="form-label form-label--inline form-label--margin">
                    for {formData.organisation.name}{" "}
                  </p>
                )}
              </Grid>
              <Grid
                container
                direction="row"
                alignItems="baseline"
                justify="space-between"
                className="pagination-buttons"
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => setDirection("back")}
                >
                  Back
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  onClick={() => setDirection("forward")}
                >
                  Next
                </Button>
              </Grid>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

FormUserDetails.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
}
