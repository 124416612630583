import React, { useState } from "react"
import PropTypes from "prop-types"
import { Formik, Form, Field } from "formik"
import { makeStyles } from "@material-ui/core/styles"
import { Button, Grid } from "@material-ui/core"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import NumberFormat from "react-number-format"
import "../styles/styles.css"
import fundraiserTestData from "../../test_data/fundraisers.json"

const useStyles = makeStyles(theme => ({
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    ["@media (max-width:991px) and (min-width:768px)"]: {
      padding:"1rem 1.2rem",
    }
  },
  button: {
    margin: theme.spacing(1),
  },
  buttonSm: {
    padding:"11px 11px",
    fontSize:"13px",
    width:"100%",
  },
  buttonLg: {
    padding:"11px 25px",
  },
  buttonFake: {
    padding:"11px 11px",
    fontSize:"13px",
    width:"100%",
    backgroundColor: "#24195c",
    fontWeight:"600",
    lineHeight:"1.75",
    textAlign:"center",
    fontFamily:"'canada-type-gibson', 'Verdana', Arial, sans-serif",
    borderRight:"1px solid #ea5045",
    transition:"0.15s ease-in-out",
    '&:hover': {
      backgroundColor: '#413582',
    },
  },
  search: {
    margin: "0 0 -2.5rem 0",
    padding: "1rem 1.2rem",
    ["@media (min-width:768px)"]: {
      marginBottom:"3rem",
    }
  },
  input: {
    border: 0,
    borderBottom:"2px solid rgba(36, 25, 92, 0.5)",
    borderRadius: "0",
    height: "50px",
    marginRight: "15px",
    ["@media (min-width:992px)"]: {
      minWidth:"270px",
      marginRight: "40px",
    },
  },
  searchForm: {
    position:"relative",
    display:"flex",
    '&:before': {
      content:"''",
      display:"inline-block",
      background:"url(/images/search.svg) center center / contain no-repeat",
      width:17,
      height:17,
      position:"absolute",
      top:16,
      left:0,
    },
  },
  header: {
    maxWidth: "300px",
  },
  controllabel: {
    flexDirection: "column",
    backgroundColor:"#ea5045",
    color:"#fff",
    display:"flex",
    width:"100%",
    justifyContent:"space-between",
    alignItems:"baseline",
    flex:1,
    margin:0,
    '& label': {
      padding:"4px 18px 11px 18px",
      display:"block",
      fontFamily:"'canada-type-gibson', 'Verdana', Arial, sans-serif",
      fontWeight:"600",
      fontSize:"15px",
      lineHeight:"1.4",
      '&:after': {
        content:"''",
        display:"block",
        width:16,
        height:16,
        marginTop:7,
        background:"url(/images/right-arrow.svg) center center / contain no-repeat",
      },
    },
    '& .MuiGrid-container': {
      width:"100%",
    },
    '& input': {
      opacity:0,
      position:"absolute",
    },
    '& span': {
      width:"100%",
      display:"block",
    },
  },
  innerfield: {
    order: 1,
    margin:"1rem",
  },
  filterLabel: {
    fontWeight:"600",
    display:"inline-block",
    boxShadow: "inset 0 -1px 0 0 #24195c",
    marginBottom: "30px",
  },
  labelImg: {
    margin:0,
    display:"block",
    height:160,
    width:"100%",
    backgroundRepeat:"no-repeat",
    backgroundSize:"cover",
    backgroundPosition:"center center",
  },
  labelTarget: {
    fontFamily:"'canada-type-gibson', 'Verdana', Arial, sans-serif",
    display:"inline-block",
    fontSize: "12px",
    fontWeight: "600",
    padding: "68px 80px 11px 64px",
    backgroundColor: "#24195c",
    color: "#fff",
    position: "absolute",
    top: "-60px",
    borderRadius: "50%",
    left: "-50px",
    width:"220px",
  },
  cardWrap: {
    position:"relative",
    overflow:"hidden",
    height:"100%",
    display:"flex",
    flexDirection:"column",
  },
  scrollPagination: {
    fontWeight:"600",
    display:"block",
    boxShadow: "inset 0 -1px 0 0 #24195c",
    textAlign:"center",
    margin:"35px auto",
  },
  cardItem: {
    width:"100%",
  },
  cardDetails: {
    backgroundColor:"#ea5045",
    color:"#fff",
    fontSize:"12px",
    padding:"8px 18px 0 18px",
    '& span': {
      '&:first-child': {
        '&:after': {
          content:"'|'",
          display:"inline-block",
          padding:"0 4px",
        }
      }
    }
  },
  buttonItem: {
    flex:1,
  },
  pieWrap: {
    ["@media (max-width:767px)"]: {
      margin:"-20px auto 0 auto",
    },
  }
}))

const RadioButton = ({
  field: { name, value, onChange, onBlur },
  id,
  label,
  className,
  ...props
}) => {
  return (
    <div>
      <input
        name={name}
        id={id}
        type="radio"
        value={id} // could be something else for output?
        checked={id === value}
        onChange={onChange}
        onBlur={onBlur}
        {...props}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  )
}

export const FormGridSearch = ({
  formData,
  setFormData,
  nextStep,
  prevStep,
}) => {
  const classes = useStyles();
  const targetPercentage = formData.totalRaised / formData.totalTarget * 100;
  const strokePercentage = targetPercentage + ',100';
  const [direction, setDirection] = useState("back");
  // Grab values and submitForm from context
  console.log(fundraiserTestData);
  return (
    <>
      <div className="no-progress-bar">
        <div className="extra-container squiggle-bottom">
          <div className="container">
            <Grid
              container
              justify="space-between"
              className={classes.search}
            >
              <Grid item sm={6}>
                <h1>Fundraising</h1>
                <p className="mb-30">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras eu pellentesque tortor. Phasellus sed tempor enim.</p>
                <div className="mb-30">
                  <Button variant="contained" color="secondary" className={classes.buttonLg}>
                    Create your own fundraiser
                  </Button>
                </div>
                <form className={classes.searchForm}>
                  <input type="text" placeholder="" className={classes.input} />
                  <Button variant="contained" color="secondary" className={classes.buttonLg}>
                    Search
                  </Button>
                </form>
              </Grid>
              <Grid item sm={6} className={classes.pieWrap}>
                <Grid
                  justify="flex-end"
                >
                <div className="pie-chart__wrapper pie-chart--lg">
                  <div className="pie-chart__inner">
                    
                      
                    <svg className="circle-chart" viewBox="0 0 33.83098862 33.83098862" width="280" height="280" xmlns="http://www.w3.org/2000/svg">
                      <circle className="circle-chart__background" stroke="#2b5b88" stroke-width="1" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431"></circle>
                      <circle className="circle-chart__circle" stroke="#50bcbd" stroke-width="1" stroke-dasharray={ strokePercentage } stroke-linecap="round" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431"></circle>
                      <g className="circle-chart__info">
                      </g>
                    </svg>
                    <div className="circle-chart__text">
                      <div className="circle-chart__percent">
                        <NumberFormat value={formData.totalRaised} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                      </div>
                      <div className="circle-chart__subline">currently achieved of <NumberFormat value={formData.totalTarget} displayType={'text'} thousandSeparator={true} prefix={'£'} /> target</div>
                    </div>
                  </div>
                </div>

                </Grid>
              </Grid>
            </Grid>

          </div>
        </div>

        <Formik
          initialValues={formData}
          onSubmit={values => {
            setFormData(Object.assign(formData, values))
            direction === "back" ? prevStep() : nextStep()
          }}
        >
          <Form className={classes.form}>
            <p className={classes.filterLabel}>Filter results </p>
            <Grid container direction="row" alignItems="stretch" spacing={5} className={classes.cardGrid}>
              {fundraiserTestData.map((fundraiser)=><FundraiserGridItem fundraiser={fundraiser} onClick={nextStep} />)}
            </Grid>
            <div className={classes.scrollPagination}>Show all</div>
          </Form>
        </Formik>
      </div>
    </>
  )
}

FormGridSearch.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
}

const FundraiserGridItem = ({fundraiser, onClick}) => {
  const formatPounds =(number) => `£${number}`;
  const classes = useStyles();
  return(
    <Grid item sm={6} md={4} className={classes.cardItem}>
      <div className={classes.cardWrap}>
        <div className={classes.labelImg} style={{backgroundImage:`url(${fundraiser.image})`}}></div>
        <div className={classes.cardDetails}>
          <span>Birmingham</span>
          <span>Jan 2021</span>
        </div>
        <FormControlLabel
          className={classes.controllabel}
          control={
            <Field
              className={classes.innerfield}
              component={RadioButton}
              name="fundraisers"
              id={`fundraiser${fundraiser.id}`}
              label={`${fundraiser.label}`}
              onClick={onClick}
            />
          }
          label={
            <>
              <div className={classes.labelTarget}>{formatPounds(fundraiser.target)} target</div>
              <Grid container className={classes.buttonWrap}>
                <Grid item md={6} className={classes.buttonItem}>
              
                  <div className={classes.buttonFake}>
                    Donate to this
                  </div>
                </Grid>
                <Grid item md={6} className={classes.buttonItem}>
                  <Button color="primary" variant="contained" className={classes.buttonSm}>
                    Create one like this
                  </Button>
                </Grid>
              </Grid>
            </>
          }
        />
      </div>
    </Grid>
  );
}