import React, { useState } from "react"
import PropTypes from "prop-types"
import { Formik, Form, Field } from "formik"
import TextField from "@material-ui/core/TextField"
import { FormHelperText } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { FormHeader } from "./form-header"
import clsx from 'clsx';
import { Button, Checkbox, Grid, Typography } from "@material-ui/core"
import "../styles/styles.css"
import * as yup from "yup"
import FormControlLabel from "@material-ui/core/FormControlLabel"

const useStyles = makeStyles(theme => ({
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    margin: theme.spacing(1),
    padding: "9px 34px",
  },
  icon: {
    borderRadius: 0,
    width:30,
    height:30,
    border:"1px solid #ea5045",
    marginRight:"5px",
  },
  checkedIcon: {
    backgroundColor:"#ea5045",
    width:30,
    height:30,
    backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
  },
  termsCheckbox: {
    display:"flex",
    fontSize:"28px",
  },
  formControlLabel: {
    fontSize:"27px",
    fontFamily:"'canada-type-gibson', 'Verdana', Arial, sans-serif",
  },
  emailField: {
    margin:"0",
    marginLeft:"15px",
    minWidth:"330px",
  },
  emailWrap: {
    display:"block",
    marginBottom:"20px",
  },
}))

const validationSchema = yup.object().shape({
  emailAddress: yup
    .string()
    .email("Invalid email")
    .required("Email is required"),
  termsAgreed: yup
    .boolean()
    .required("The terms and conditions must be accepted.")
    .oneOf([true], "The terms and conditions must be accepted."),
})

export const FormFundraiseTerms = ({
  formData,
  setFormData,
  nextStep,
  prevStep,
  submitForm,
}) => {
  const classes = useStyles()
  const [direction, setDirection] = useState("back")
  return (
    <>
      {/* <FormHeader
        title={`${formData.firstName}, it is thanks to people like you that we can keep making a difference through life-saving research.`}
      /> */}
      <Formik
        initialValues={formData}
        onSubmit={values => {
          setFormData(Object.assign(formData, values))
          direction === "back"
            ? prevStep()
            : direction === "submit"
            ? submitForm()
            : nextStep()
        }}
        validationSchema={validationSchema}
      >
        {({ errors, touched, values }) => (
          <Form className={classes.form}>
            <div className="extra-container details-wrapper">
              <div className="bg--grey bg--image">
                <div className="container">
                  <h3 className="form-subheading">
                    Fantastic. Let's get everything set up and your fundraiser
                    published for all to see!
                  </h3>
                  <div className={classes.emailWrap}>
                    <p className="form-label form-label--inline">
                      My email address is{" "}
                    </p>
                    <Field
                      type="email"
                      name="emailAddress"
                      label="Email *"
                      className={classes.emailField}
                      margin="normal"
                      as={TextField}
                      error={touched.emailAddress && errors.emailAddress}
                      helperText={touched.emailAddress && errors.emailAddress}
                    />
                  </div>
                  
                  <FormControlLabel
                    control={
                      <Field
                        type="checkbox"
                        name="termsAgreed"
                        className={classes.termsCheckbox}
                        icon={<span className={classes.icon} />}
                        checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                        as={Checkbox}
                        Label={{ label: "I accept the terms and conditions" }}
                      />
                    }
                    label={<Typography className={classes.formControlLabel}>I agree to {formData.organisation.namePlural} terms and conditions.</Typography>}
                  />

                  {touched.termsAgreed && errors.termsAgreed ? (
                    <FormHelperText error>{errors.termsAgreed}</FormHelperText>
                  ) : null}

                  <Grid
                    container
                    direction="row"
                    alignItems="baseline"
                    justify="space-between"
                    className="pagination-buttons"
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={() => setDirection("back")}
                    >
                      Back
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                      onClick={() => setDirection("submit")}
                    >
                      Next
                    </Button>
                  </Grid>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

FormFundraiseTerms.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
}
