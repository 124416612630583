import React from "react"
import PropTypes from "prop-types"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: "0 0 3rem 0",
  },
  title: {
    textAlign:"left",
    margin:"0 0 2rem 0",
  },
  inner: {
    boxShadow: "none",
    backgroundColor:"#fff",
    color:"#24195c",
  },
}))

export const FormHeader = ({ title }) => {
  const classes = useStyles()
  return (
    <div className="extra-container header-section header-section--top {classes.root}">
      <div className="container">
        <AppBar position="static" color="default" className={classes.inner}>
          <Toolbar>
            <Typography variant="h2" className={classes.title}>
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
      </div>
    </div>
  )
}

FormHeader.propTypes = {
  title: PropTypes.string.isRequired,
}
