import React, { useState } from "react"
import PropTypes from "prop-types"
import { Formik, Form, Field } from "formik"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"
import { FormHeader } from "./form-header"
import { Button } from "@material-ui/core"
import { Grid } from "@material-ui/core"
import "../styles/styles.css"

const useStyles = makeStyles(theme => ({
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    margin: theme.spacing(1),
  },
  textarea: {
    border: 0,
    lineHeight: "4ch",
    backgroundColor:"transparent",
    backgroundImage:
      "linear-gradient(transparent, transparent calc(4ch - 1px), #df6f64 0px)",
    backgroundSize: "100% 4ch",
    width: "100%",
    '&:focus': {
      outline:"2px dotted rgba(234, 80, 69, 0.25)",
    },
  },
}))

export const FormFundraiseDetails = ({
  formData,
  setFormData,
  nextStep,
  prevStep,
}) => {
  const classes = useStyles()
  const [direction, setDirection] = useState("back")
  return (
    <>
      {/* <FormHeader title="It is thanks to people like you that we can keep making a difference through life-saving research." />
       */}
      <Formik
        initialValues={formData}
        onSubmit={values => {
          setFormData(Object.assign(formData, values))
          direction === "back" ? prevStep() : nextStep()
        }}
      >
        <Form className={classes.form}>
          <div className="extra-container details-wrapper">
            <div className="bg--grey bg--image">
              <div className="container">
                <h3 className="form-subheading">
                  So you're going to raise {formData.targetAmount} by{" "}
                  {formData.waysToFundraise}. Thank you! So, what else do your
                  supporters need to know?
                </h3>
                <Field
                  name="fundraiserDescription"
                  label="Describe"
                  margin="normal"
                  component="textarea"
                  placeholder="Describe your fundraiser here and encourage people to support you and donate"
                  className={classes.textarea}
                />
                <Grid
                  container
                  direction="row"
                  alignItems="baseline"
                  justify="space-between"
                  className="pagination-buttons"
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => setDirection("back")}
                  >
                    Back
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    onClick={() => setDirection("forward")}
                  >
                    Next
                  </Button>
                </Grid>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    </>
  )
}

FormFundraiseDetails.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
}
